*:focus {
    outline: none;
}

.GridView {
    background-color: #fafafa;
}

.GridRow {
    position: relative;
    display: flex;
    flex-direction: row;
}

.GridColumn {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.LeftSideGridContainer {
    flex: 0 0 75px;
    z-index: 10;
    box-shadow: 1px 0 0 0 #f2f2f2;
}

.LeftSideGridContainer.scroll {
    box-shadow: 1px 0 0 0 #f2f2f2, 4px 0 0 0 rgba(0,0,0,0.04);
}

.LeftSideGrid {
    overflow: hidden !important;
}

.HeaderGrid {
    width: 100%;
    overflow: hidden !important;
    border-bottom: 1px solid #f2f2f2;
    background-color: hsla(0,0%,100%,0.5);
}

.HeaderGrid.scroll {
    box-shadow: 0 4px 0 0 rgba(0,0,0,0.04);
}

.BodyGrid {
    width: 100%;
}

.evenRow {}

.oddRow {
    background-color: rgba(0, 0, 0, .1);
}

.cell,
.headerCell,
.leftCell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.headerCell {
    background-color: #fff;
    padding: 0 8px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
}

.headerCell:first-of-type {
    border-left: none;
}

.cell {
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    font-size: 13px;
    padding: 0 8px;
    white-space: nowrap;
    cursor: pointer;
    color: #737373;
}

.cell.hover {
    background:#f7fbff;
}

.cell.active {
    margin-top: -1px;
    border-top: 1px solid #2d7ff9;
    border-bottom: 1px solid #2d7ff9;
    z-index: 1000 !important;
}

.cellFocus {
    border-color: transparent;
    box-shadow: 0 0 0 2px #2d7ff9 !important;
    border-radius: 1px;
    z-index: 1000 !important;
}

.addRecordCell {
    border-right: none;
    border-left: none;
}

.headerCell,
.leftCell {
    font-weight: bold;
    color: #737373;
    font-size: 14px;
}
